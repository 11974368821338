/* Luna Set Up */
$include-luna-font-faces: false;
$include-guardian-fonts: false;

$assets-path: "~@uitk/ui-toolkit/dist/css/nm-px/assets";
$global-flexbox: true;
/* Luna library import, the tilde is a webpack convention */
@import "~@uitk/ui-toolkit/src/scss/themes/nm-px/luna.scss";
/* Suggested height reset */
html,
body {
  height: 100%;
}
/* Your app specific CSS goes here */

.App {
  background-color: $color-neutral-90;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

.App-container {
  min-height: 90vh;
}

.App-footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

.App-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-password-confirmation-card {
  margin-bottom: 3rem;
}

//LUNA UPDATES

.ssr-user-registration-card, .App-password-confirmation-card {
  .luna-system_message {
    width: 100%;
    margin-bottom: 1rem;
  }
}

//DEV

.dev-nav {
  position: fixed;
  top: 0;
  left: 0;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: gray;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 0.1em 0.25em;
    text-decoration: none;
  }

  li a:hover {
    background-color: darkgray;
  }
}
